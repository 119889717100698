// GET ALL PROJECT
export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST'
export const GET_PROJECT_REQUEST_SUCCESS = 'GET_PROJECT_REQUEST_SUCCESS'
export const GET_PROJECT_REQUEST_FAIL = 'GET_PROJECT_REQUEST_FAIL'

// GET ALL LOG BY CODE
export const GET_ALL_LOG_BY_CODE_REQUEST = 'GET_ALL_LOG_BY_CODE_REQUEST'
export const GET_ALL_LOG_BY_CODE_SUCCESS = 'GET_ALL_LOG_BY_CODE_SUCCESS'
export const GET_ALL_LOG_BY_CODE_FAIL = 'GET_ALL_LOG_BY_CODE_FAIL'

// UPLOAD PROJECT
export const UPLOAD_NEW_PROJECT_REQUEST = 'UPLOAD_NEW_PROJECT_REQUEST'
export const UPLOAD_NEW_PROJECT_REQUEST_SUCCESS = 'UPLOAD_NEW_PROJECT_REQUEST_SUCCESS'
export const UPLOAD_NEW_PROJECT_REQUEST_FAIL = 'UPLOAD_NEW_PROJECT_REQUEST_FAIL'

// UPLOAD PROJECT 
export const UPLOAD_NEW_PROJECT_REQUEST_RESET = 'UPLOAD_NEW_PROJECT_REQUEST_RESET'

// GET LOG COUNT
export const GET_LOG_COUNT_REQUEST = 'GET_LOG_COUNT_REQUEST'
export const GET_LOG_COUNT_SUCCESS = 'GET_LOG_COUNT_SUCCESS'
export const GET_LOG_COUNT_FAIL = 'GET_LOG_COUNT_FAIL'

// GET LOG COUNT BY DATE
export const GET_LOG_COUNT_BY_DATE_REQUEST = 'GET_LOG_COUNT_BY_DATE_REQUEST'
export const GET_LOG_COUNT_BY_DATE_SUCCESS = 'GET_LOG_COUNT_BY_DATE_SUCCESS'
export const GET_LOG_COUNT_BY_DATE_FAIL = 'GET_LOG_COUNT_BY_DATE_FAIL'

// GET ERROR WRT OS
export const GET_ERROR_WRT_OS_REQUEST = 'GET_ERROR_WRT_OS_REQUEST'
export const GET_ERROR_WRT_OS_REQUEST_SUCCESS = 'GET_ERROR_WRT_OS_REQUEST_SUCCESS'
export const GET_ERROR_WRT_OS_REQUEST_FAIL = 'GET_ERROR_WRT_OS_REQUEST_FAIL'

// GET ERROR BY VERSION
export const GET_ERROR_COUNT_BY_VERSION_REQUEST = 'GET_ERROR_COUNT_BY_VERSION_REQUEST'
export const GET_ERROR_COUNT_BY_VERSION_REQUEST_SUCCESS = 'GET_ERROR_COUNT_BY_VERSION_REQUEST_SUCCESS'
export const GET_ERROR_COUNT_BY_VERSION_REQUEST_FAIL = 'GET_ERROR_COUNT_BY_VERSION_REQUEST_FAIL'

// GET DEVICE INFO
export const GET_DEVICE_INFO_REQUEST = 'GET_DEVICE_INFO_REQUEST'
export const GET_DEVICE_INFO_REQUEST_SUCCESS = 'GET_DEVICE_INFO_REQUEST_SUCCESS'
export const GET_DEVICE_INFO_REQUEST_FAIL = 'GET_DEVICE_INFO_REQUEST_FAIL'

// GET COUNT MSG OCCURRENCE
export const GET_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST = 'GET_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST'
export const GET_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST_SUCCESS = 'GET_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST_SUCCESS'
export const GET_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST_FAIL = 'GET_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST_FAIL'

// GET CRASH FREE USER
export const GET_CRASH_FREE_USERS_REQUEST = 'GET_CRASH_FREE_USERS_REQUEST'
export const GET_CRASH_FREE_USERS_REQUEST_SUCCESS = 'GET_CRASH_FREE_USERS_REQUEST_SUCCESS'
export const GET_CRASH_FREE_USERS_REQUEST_FAIL = 'GET_CRASH_FREE_USERS_REQUEST_FAIL'

// GET CRASH DATA
export const GET_CRASH_ANALYTICS_DATA_REQUEST = 'GET_CRASH_ANALYTICS_DATA_REQUEST'
export const GET_CRASH_ANALYTICS_DATA_REQUEST_SUCCESS = 'GET_CRASH_ANALYTICS_DATA_REQUEST_SUCCESS'
export const GET_CRASH_ANALYTICS_DATA_REQUEST_FAIL = 'GET_CRASH_ANALYTICS_DATA_REQUEST_FAIL'

// GET CRASH FREE USER
export const GET_CRASH_FREE_USERS_DATA_REQUEST = 'GET_CRASH_FREE_USERS_DATA_REQUEST'
export const GET_CRASH_FREE_USERS_DATA_REQUEST_SUCCESS = 'GET_CRASH_FREE_USERS_DATA_REQUEST_SUCCESS'
export const GET_CRASH_FREE_USERS_DATA_REQUEST_FAIL = 'GET_CRASH_FREE_USERS_DATA_REQUEST_FAIL'

// GET MODEL CODE
export const GET_MODEL_CODE_REQUEST = 'GET_MODEL_CODE_REQUEST'
export const GET_MODEL_CODE_SUCCESS = 'GET_MODEL_CODE_SUCCESS'
export const GET_MODEL_CODE_FAIL = 'GET_MODEL_CODE_FAIL'

// CRASH EMAIL
export const ADD_CRASH_EMAIL_REQUEST = 'ADD_CRASH_EMAIL_REQUEST'
export const ADD_CRASH_EMAIL_REQUEST_SUCCESS = 'ADD_CRASH_EMAIL_REQUEST_SUCCESS'
export const ADD_CRASH_EMAIL_REQUEST_FAIL = 'ADD_CRASH_EMAIL_REQUEST_FAIL'

// GET PROJECT BY CODE
export const GET_PROJECT_BY_CODE_REQUEST = 'GET_PROJECT_BY_CODE_REQUEST'
export const GET_PROJECT_BY_CODE_REQUEST_SUCCESS = 'GET_PROJECT_BY_CODE_REQUEST_SUCCESS'
export const GET_PROJECT_BY_CODE_REQUEST_FAIL = 'GET_PROJECT_BY_CODE_REQUEST_FAIL'

// GET CRASH PROJECT EMAIL 
export const GET_PROJECT_CRASH_EMAIL_REQUEST = 'GET_PROJECT_CRASH_EMAIL_REQUEST'
export const GET_PROJECT_CRASH_EMAIL_REQUEST_SUCCESS = 'GET_PROJECT_CRASH_EMAIL_REQUEST_SUCCESS'
export const GET_PROJECT_CRASH_EMAIL_REQUEST_FAIL = 'GET_PROJECT_CRASH_EMAIL_REQUEST_FAIL'

//GET DEVICE REGISTRATION DETAILS
export const GET_DEVICE_DETAILS_REQUEST = "GET_DEVICE_DETAILS_REQUEST"
export const GET_DEVICE_DETAILS_SUCCESS = "GET_DEVICE_DETAILS_SUCCESS"
export const GET_DEVICE_DETAILS_FAIL = "GET_DEVICE_DETAILS_FAIL"

// GET_SALES_DEMO_DEVICE_COUNT_REQUEST
export const GET_SALES_DEMO_DEVICE_COUNT_REQUEST = "GET_SALES_DEMO_DEVICE_COUNT_REQUEST"
export const GET_SALES_DEMO_DEVICE_COUNT_SUCCESS = "GET_SALES_DEMO_DEVICE_COUNT_SUCCESS"
export const GET_SALES_DEMO_DEVICE_COUNT_FAIL = "GET_SALES_DEMO_DEVICE_COUNT_FAIL"

export const GET_DEMO_DEVICE_COUNT_REQUEST = "GET_DEMO_DEVICE_COUNT_REQUEST"
export const GET_DEMO_DEVICE_COUNT_SUCCESS = "GET_DEMO_DEVICE_COUNT_SUCCESS"
export const GET_DEMO_DEVICE_COUNT_FAIL = "GET_DEMO_DEVICE_COUNT_FAIL"

export const UPLOAD_SALES_LEAD_REQUEST = "UPLOAD_SALES_LEAD_REQUEST"
export const UPLOAD_SALES_LEAD_SUCCESS = "UPLOAD_SALES_LEAD_SUCCESS"
export const UPLOAD_SALES_LEAD_FAIL = "UPLOAD_SALES_LEAD_FAIL"

export const GET_ALL_SALES_LEADS_REQUEST = "GET_ALL_SALES_LEADS_REQUEST"
export const GET_ALL_SALES_LEADS_SUCCESS = "GET_ALL_SALES_LEADS_SUCCESS"
export const GET_ALL_SALES_LEADS_FAIL = "GET_ALL_SALES_LEADS_FAIL"

export const GET_SINGLE_SALE_LEAD_REQUEST = "GET_SINGLE_SALE_LEAD_REQUEST"
export const GET_SINGLE_SALE_LEAD_SUCCESS = "GET_SINGLE_SALE_LEAD_SUCCESS"
export const GET_SINGLE_SALE_LEAD_FAIL = "GET_SINGLE_SALE_LEAD_FAIL"

export const EDIT_SALES_LEAD_REQUEST = "EDIT_SALES_LEAD_REQUEST"
export const EDIT_SALES_LEAD_SUCCESS = "EDIT_SALES_LEAD_SUCCESS"
export const EDIT_SALES_LEAD_FAIL = "EDIT_SALES_LEAD_FAIL"

// POST_UPDATE_DEMO_DEVICES_REQUEST
export const POST_UPDATE_DEMO_DEVICES_REQUEST = "POST_UPDATE_DEMO_DEVICES_REQUEST"
export const POST_UPDATE_DEMO_DEVICES_SUCCESS = "POST_UPDATE_DEMO_DEVICES_SUCCESS"
export const POST_UPDATE_DEMO_DEVICES_FAIL = "POST_UPDATE_DEMO_DEVICES_FAIL"

// GET_DEMO_LEAD_COUNT_REQUEST
export const GET_DEMO_LEAD_COUNT_REQUEST = "GET_DEMO_LEAD_COUNT_REQUEST"
export const GET_DEMO_LEAD_COUNT_SUCCESS = "GET_DEMO_LEAD_COUNT_SUCCESS"
export const GET_DEMO_LEAD_COUNT_FAIL = "GET_DEMO_LEAD_COUNT_FAIL"

// POST_UPDATE_PAYMENT_SALES_REQUEST
export const POST_UPDATE_PAYMENT_SALES_REQUEST = "POST_UPDATE_PAYMENT_SALES_REQUEST"
export const POST_UPDATE_PAYMENT_SALES_SUCCESS = "POST_UPDATE_PAYMENT_SALES_SUCCESS"
export const POST_UPDATE_PAYMENT_SALES_FAIL = "POST_UPDATE_PAYMENT_SALES_FAIL"


// POST_UPDATE_DISPATCH_DEMO_REQUEST
export const POST_UPDATE_DISPATCH_DEMO_REQUEST = "POST_UPDATE_DISPATCH_DEMO_REQUEST"
export const POST_UPDATE_DISPATCH_DEMO_SUCCESS = "POST_UPDATE_DISPATCH_DEMO_SUCCESS"
export const POST_UPDATE_DISPATCH_DEMO_FAIL = "POST_UPDATE_DISPATCH_DEMO_FAIL"

// POST_DEMO_COMPLETED_REQUEST
export const POST_DEMO_COMPLETED_REQUEST = "POST_DEMO_COMPLETED_REQUEST"
export const POST_DEMO_COMPLETED_SUCCESS = "POST_DEMO_COMPLETED_SUCCESS"
export const POST_DEMO_COMPLETED_FAIL = "POST_DEMO_COMPLETED_FAIL"

// UPDATE_SALES__PAYMENT_REQUEST
export const UPDATE_SALES__PAYMENT_REQUEST = "UPDATE_SALES__PAYMENT_REQUEST"
export const UPDATE_SALES__PAYMENT_SUCCESS = "UPDATE_SALES__PAYMENT_SUCCESS"
export const UPDATE_SALES__PAYMENT_FAIL = "UPDATE_SALES__PAYMENT_FAIL"

export const UPDATE_SALES_CONFIRMED_REQUEST = "UPDATE_SALES_CONFIRMED_REQUEST"
export const UPDATE_SALES_CONFIRMED_SUCCESS = "UPDATE_SALES_CONFIRMED_SUCCESS"
export const UPDATE_SALES_CONFIRMED_FAIL = "UPDATE_SALES_CONFIRMED_FAIL"

export const UPDATE_DISPATCH_SALE_REQUEST = "UPDATE_DISPATCH_SALE_REQUEST"
export const UPDATE_DISPATCH_SALE_SUCCESS = "UPDATE_DISPATCH_SALE_SUCCESS"
export const UPDATE_DISPATCH_SALE_FAIL = "UPDATE_DISPATCH_SALE_FAIL"

// ADD_PAYMENT_UPDATE_REQUEST
export const ADD_PAYMENT_UPDATE_REQUEST = "ADD_PAYMENT_UPDATE_REQUEST"
export const ADD_PAYMENT_UPDATE_SUCCESS = "ADD_PAYMENT_UPDATE_SUCCESS"
export const ADD_PAYMENT_UPDATE_FAIL = "ADD_PAYMENT_UPDATE_FAIL"

// GET_DEMO_DEVICE_GRAPH_COUNT_REQUEST
export const GET_DEMO_DEVICE_GRAPH_COUNT_REQUEST = "GET_DEMO_DEVICE_GRAPH_COUNT_REQUEST"
export const GET_DEMO_DEVICE_GRAPH_COUNT_SUCCESS = "GET_DEMO_DEVICE_GRAPH_COUNT_SUCCESS"
export const GET_DEMO_DEVICE_GRAPH_COUNT_FAIL = "GET_DEMO_DEVICE_GRAPH_COUNT_FAIL"

// GET_DEMO_DEVICE_YEAR_MONTH_WISE_REQUEST
export const GET_DEMO_DEVICE_YEAR_MONTH_WISE_REQUEST = "GET_DEMO_DEVICE_YEAR_MONTH_WISE_REQUEST"
export const GET_DEMO_DEVICE_YEAR_MONTH_WISE_SUCCESS = "GET_DEMO_DEVICE_YEAR_MONTH_WISE_SUCCESS"
export const GET_DEMO_DEVICE_YEAR_MONTH_WISE_FAIL = "GET_DEMO_DEVICE_YEAR_MONTH_WISE_FAIL"

// GET_TOTAL_DEVICE_COUNT_DETAILS_REQUEST
export const GET_TOTAL_DEVICE_COUNT_DETAILS_REQUEST = "GET_TOTAL_DEVICE_COUNT_DETAILS_REQUEST"
export const GET_TOTAL_DEVICE_COUNT_DETAILS_SUCCESS = "GET_TOTAL_DEVICE_COUNT_DETAILS_SUCCESS"
export const GET_TOTAL_DEVICE_COUNT_DETAILS_FAIL = "GET_TOTAL_DEVICE_COUNT_DETAILS_FAIL"

// GET_DEVICE_SUMARRY_REQUEST
export const GET_DEVICE_SUMARRY_REQUEST = "GET_DEVICE_SUMARRY_REQUEST"
export const GET_DEVICE_SUMARRY_SUCCESS = "GET_DEVICE_SUMARRY_SUCCESS"
export const GET_DEVICE_SUMARRY_FAIL = "GET_DEVICE_SUMARRY_FAIL"

// GET_DEVICE_GRAPH_COUNT_REQUEST
export const GET_DEVICE_GRAPH_COUNT_REQUEST = "GET_DEVICE_GRAPH_COUNT_REQUEST"
export const GET_DEVICE_GRAPH_COUNT_SUCCESS = "GET_DEVICE_GRAPH_COUNT_SUCCESS"
export const GET_DEVICE_GRAPH_COUNT_FAIL = "GET_DEVICE_GRAPH_COUNT_FAIL"

// GET_WEEKLY_DISPATCH_DATA_REQUEST
export const GET_WEEKLY_DISPATCH_DATA_REQUEST = "GET_WEEKLY_DISPATCH_DATA_REQUEST"
export const GET_WEEKLY_DISPATCH_DATA_SUCCESS = "GET_WEEKLY_DISPATCH_DATA_SUCCESS"
export const GET_WEEKLY_DISPATCH_DATA_FAIL = "GET_WEEKLY_DISPATCH_DATA_FAIL"

// GET_MONTHLY_DISPATCH_DATA_REQUEST
export const GET_MONTHLY_DISPATCH_DATA_REQUEST = "GET_MONTHLY_DISPATCH_DATA_REQUEST"
export const GET_MONTHLY_DISPATCH_DATA_SUCCESS = "GET_MONTHLY_DISPATCH_DATA_SUCCESS"
export const GET_MONTHLY_DISPATCH_DATA_FAIL = "GET_MONTHLY_DISPATCH_DATA_FAIL"

// GET_DISPATCH_DEVICE_CHART_DATA_REQUEST
export const GET_DISPATCH_DEVICE_CHART_DATA_REQUEST = "GET_DISPATCH_DEVICE_CHART_DATA_REQUEST"
export const GET_DISPATCH_DEVICE_CHART_DATA_SUCCESS = "GET_DISPATCH_DEVICE_CHART_DATA_SUCCESS"
export const GET_DISPATCH_DEVICE_CHART_DATA_FAIL = "GET_DISPATCH_DEVICE_CHART_DATA_FAIL"