
// VERIFY_EMAIL_FOR_SUPPORT_REQUEST
export const VERIFY_EMAIL_FOR_SUPPORT_REQUEST = 'VERIFY_EMAIL_FOR_SUPPORT_REQUEST'
export const VERIFY_EMAIL_FOR_SUPPORT_REQUEST_SUCCESS = 'VERIFY_EMAIL_FOR_SUPPORT_REQUEST_SUCCESS'
export const VERIFY_EMAIL_FOR_SUPPORT_REQUEST_FAIL = 'VERIFY_EMAIL_FOR_SUPPORT_REQUEST_FAIL'

// VERIFY_OTP_FOR_SUPPORT_REQUEST
export const VERIFY_OTP_FOR_SUPPORT_REQUEST = 'VERIFY_OTP_FOR_SUPPORT_REQUEST'
export const VERIFY_OTP_FOR_SUPPORT_REQUEST_SUCCESS = 'VERIFY_OTP_FOR_SUPPORT_REQUEST_SUCCESS'
export const VERIFY_OTP_FOR_SUPPORT_REQUEST_FAIL = 'VERIFY_OTP_FOR_SUPPORT_REQUEST_FAIL'

// VERIFY_OTP_FOR_SUPPORT_REQUEST
export const GET_DEVICEID_BY_HOSPITAL_REQUEST = 'GET_DEVICEID_BY_HOSPITAL_REQUEST'
export const GET_DEVICEID_BY_HOSPITAL_SUCCESS = 'GET_DEVICEID_BY_HOSPITAL_SUCCESS'
export const GET_DEVICEID_BY_HOSPITAL_FAIL = 'GET_DEVICEID_BY_HOSPITAL_FAIL'
