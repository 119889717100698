// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

.ServiceEngUsrModule_parent__vfaW0 {
    display: flex;
    justify-content: space-around;
    max-width: 800px;
    gap: 30px;
    flex-wrap: wrap;
}

.ServiceEngUsrModule_card__DJgCE {
    display: flex;
    gap: 10px;
    background-color: white;
    /* border: 1px solid #ccc; */
    border-radius: 18px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    padding: 60px;
    margin: 10px;
    max-width: 400px; /* Limit the width of each card */
}

.ServiceEngUsrModule_card__DJgCE h2 {
    margin-top: 0;
}

.ServiceEngUsrModule_card__DJgCE p {
    color: #666;
}
`, "",{"version":3,"sources":["webpack://./src/css/ServiceEngUsrModule.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,SAAS;IACT,UAAU;IACV,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;IAChB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,4BAA4B;IAC5B,mBAAmB;IACnB,sFAAsF;IACtF,aAAa;IACb,YAAY;IACZ,gBAAgB,EAAE,iCAAiC;AACvD;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;AACf","sourcesContent":["body {\n    font-family: Arial, sans-serif;\n    margin: 0;\n    padding: 0;\n    background-color: #f4f4f4;\n}\n\n.parent {\n    display: flex;\n    justify-content: space-around;\n    max-width: 800px;\n    gap: 30px;\n    flex-wrap: wrap;\n}\n\n.card {\n    display: flex;\n    gap: 10px;\n    background-color: white;\n    /* border: 1px solid #ccc; */\n    border-radius: 18px;\n    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;\n    padding: 60px;\n    margin: 10px;\n    max-width: 400px; /* Limit the width of each card */\n}\n\n.card h2 {\n    margin-top: 0;\n}\n\n.card p {\n    color: #666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parent": `ServiceEngUsrModule_parent__vfaW0`,
	"card": `ServiceEngUsrModule_card__DJgCE`
};
export default ___CSS_LOADER_EXPORT___;
