import React from 'react'


function PatientDevices() {
  return (
            <div
                style={{
                    position: "relative",
                    top: "4rem",
                    marginLeft: "2rem",
                    width: "97%",
                    display: 'flex',
                    gap: '2rem'
                }}>
                helo
            </div>
  )
}

export default PatientDevices