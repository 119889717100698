// STATE NAME DATA
export const DISPATCH_DETAILS_REQUEST = 'DISPATCH_DETAILS_REQUEST'
export const DISPATCH_DETAILS_SUCCESS = 'DISPATCH_DETAILS_SUCCESS'
export const DISPATCH_DETAILS_FAIL = 'DISPATCH_DETAILS_FAIL'

export const GET_DISPATCH_DETAILS_DATA_REQUEST = 'GET_DISPATCH_DETAILS_DATA_REQUEST'
export const GET_DISPATCH_DETAILS_DATA_SUCCESS = 'GET_DISPATCH_DETAILS_DATA_SUCCESS'
export const GET_DISPATCH_DETAILS_DATA_FAIL = 'GET_DISPATCH_DETAILS_DATA_FAIL'

export const GET_DISPATCH_DETAILS_DATA_BYID_REQUEST = 'GET_DISPATCH_DETAILS_DATA_BYID_REQUEST'
export const GET_DISPATCH_DETAILS_DATA_BYID_SUCCESS = 'GET_DISPATCH_DETAILS_DATA_BYID_SUCCESS'
export const GET_DISPATCH_DETAILS_DATA_BYID_FAIL = 'GET_DISPATCH_DETAILS_DATA_BYID_FAIL'

export const PRODUCTION_DETAILS_REQUEST = 'PRODUCTION_DETAILS_REQUEST'
export const PRODUCTION_DETAILS_SUCCESS = 'PRODUCTION_DETAILS_SUCCESS'
export const PRODUCTION_DETAILS_FAIL = 'PRODUCTION_DETAILS_FAIL'

export const GET_PRODUCTION_DETAILS_DATA_REQUEST = 'GET_PRODUCTION_DETAILS_DATA_REQUEST'
export const GET_PRODUCTION_DETAILS_DATA_SUCCESS = 'GET_PRODUCTION_DETAILS_DATA_SUCCESS'
export const GET_PRODUCTION_DETAILS_DATA_FAIL = 'GET_PRODUCTION_DETAILS_DATA_FAIL'


// GET_PRODUCTION_DETAILS_DATA_BY_ID_REQUEST
export const GET_PRODUCTION_DETAILS_DATA_BY_ID_REQUEST = 'GET_PRODUCTION_DETAILS_DATA_BY_ID_REQUEST'
export const GET_PRODUCTION_DETAILS_DATA_BY_ID_SUCCESS = 'GET_PRODUCTION_DETAILS_DATA_BY_ID_SUCCESS'
export const GET_PRODUCTION_DETAILS_DATA_BY_ID_FAIL = 'GET_PRODUCTION_DETAILS_DATA_BY_ID_FAIL'

// GET_PRODUCTION_DETAILS_DATA_BY_ID_REQUEST
export const GET_DHR_UPLOAD_FILE_REQUEST = 'GET_DHR_UPLOAD_FILE_REQUEST'
export const GET_DHR_UPLOAD_FILE_SUCCESS = 'GET_DHR_UPLOAD_FILE_SUCCESS'
export const GET_DHR_UPLOAD_FILE_FAIL = 'GET_DHR_UPLOAD_FILE_FAIL'

// GET_PINCODE_DATA_REQUEST
export const GET_PINCODE_DATA_REQUEST = 'GET_PINCODE_DATA_REQUEST'
export const GET_PINCODE_DATA_SUCCESS = 'GET_PINCODE_DATA_SUCCESS'
export const GET_PINCODE_DATA_FAIL = 'GET_PINCODE_DATA_FAIL'

// GET_DEVICEID_FROM_PRODUCTION_REQUEST
export const GET_DEVICEID_FROM_PRODUCTION_REQUEST = 'GET_DEVICEID_FROM_PRODUCTION_REQUEST'
export const GET_DEVICEID_FROM_PRODUCTION_SUCCESS = 'GET_DEVICEID_FROM_PRODUCTION_SUCCESS'
export const GET_DEVICEID_FROM_PRODUCTION_FAIL = 'GET_DEVICEID_FROM_PRODUCTION_FAIL'

// GET_EDIT_DISPATCH_DATA_REQUEST
export const GET_EDIT_DISPATCH_DATA_REQUEST = 'GET_EDIT_DISPATCH_DATA_REQUEST'
export const GET_EDIT_DISPATCH_DATA_SUCCESS = 'GET_EDIT_DISPATCH_DATA_SUCCESS'
export const GET_EDIT_DISPATCH_DATA_FAIL = 'GET_EDIT_DISPATCH_DATA_FAIL'

// GET_SINGLE_HOSPITAL_DETAILS_REQUEST
export const GET_SINGLE_HOSPITAL_DETAILS_REQUEST = 'GET_SINGLE_HOSPITAL_DETAILS_REQUEST'
export const GET_SINGLE_HOSPITAL_DETAILS_SUCCESS = 'GET_SINGLE_HOSPITAL_DETAILS_SUCCESS'
export const GET_SINGLE_HOSPITAL_DETAILS_FAIL = 'GET_SINGLE_HOSPITAL_DETAILS_FAIL'

// GET_SERIAL_NUMBER_LIST_REQUEST
export const GET_SERIAL_NUMBER_LIST_REQUEST = 'GET_SERIAL_NUMBER_LIST_REQUEST'
export const GET_SERIAL_NUMBER_LIST_SUCCESS = 'GET_SERIAL_NUMBER_LIST_SUCCESS'
export const GET_SERIAL_NUMBER_LIST_FAIL = 'GET_SERIAL_NUMBER_LIST_FAIL'

// GET_SINGLE_SERIAL_NO_TRACK_DATA_REQUEST
export const GET_SINGLE_SERIAL_NO_TRACK_DATA_REQUEST = 'GET_SINGLE_SERIAL_NO_TRACK_DATA_REQUEST'
export const GET_SINGLE_SERIAL_NO_TRACK_DATA_SUCCESS = 'GET_SINGLE_SERIAL_NO_TRACK_DATA_SUCCESS'
export const GET_SINGLE_SERIAL_NO_TRACK_DATA_FAIL = 'GET_SINGLE_SERIAL_NO_TRACK_DATA_FAIL'

// GET_ACCOUNT_DETAILS_REQUEST
export const GET_ACCOUNT_DETAILS_REQUEST = 'GET_ACCOUNT_DETAILS_REQUEST'
export const GET_ACCOUNT_DETAILS_SUCCESS = 'GET_ACCOUNT_DETAILS_SUCCESS'
export const GET_ACCOUNT_DETAILS_FAIL = 'GET_ACCOUNT_DETAILS_FAIL'

// GET_ACCOUNT_PENDING_DETAILS_REQUEST
export const GET_ACCOUNT_PENDING_DETAILS_REQUEST = 'GET_ACCOUNT_PENDING_DETAILS_REQUEST'
export const GET_ACCOUNT_PENDING_DETAILS_SUCCESS = 'GET_ACCOUNT_PENDING_DETAILS_SUCCESS'
export const GET_ACCOUNT_PENDING_DETAILS_FAIL = 'GET_ACCOUNT_PENDING_DETAILS_FAIL'

// POST_ACCOUNT_DATA_WITH_FILE_REQUEST
export const POST_ACCOUNT_DATA_WITH_FILE_REQUEST = 'POST_ACCOUNT_DATA_WITH_FILE_REQUEST'
export const POST_ACCOUNT_DATA_WITH_FILE_SUCCESS = 'POST_ACCOUNT_DATA_WITH_FILE_SUCCESS'
export const POST_ACCOUNT_DATA_WITH_FILE_FAIL = 'POST_ACCOUNT_DATA_WITH_FILE_FAIL'

// POST_SHIPMENT_DETAILS_REDUCER
export const POST_SHIPMENT_DETAILS_REDUCER = 'POST_SHIPMENT_DETAILS_REDUCER'
export const POST_SHIPMENT_DETAILS_SUCCESS = 'POST_SHIPMENT_DETAILS_SUCCESS'
export const POST_SHIPMENT_DETAILS_FAIL = 'POST_SHIPMENT_DETAILS_FAIL'



// GET_AWATING_SHIPMENT_WATING_REQUEST
export const GET_AWATING_SHIPMENT_WATING_REQUEST = 'GET_AWATING_SHIPMENT_WATING_REQUEST'
export const GET_AWATING_SHIPMENT_WATING_SUCCESS = 'GET_AWATING_SHIPMENT_WATING_SUCCESS'
export const GET_AWATING_SHIPMENT_WATING_FAIL = 'GET_AWATING_SHIPMENT_WATING_FAIL'




// GET_DATA_BY_SERIAL_NUMBER_REQUEST
export const GET_DATA_BY_SERIAL_NUMBER_REQUEST = 'GET_DATA_BY_SERIAL_NUMBER_REQUEST'
export const GET_DATA_BY_SERIAL_NUMBER_SUCCESS = 'GET_DATA_BY_SERIAL_NUMBER_SUCCESS'
export const GET_DATA_BY_SERIAL_NUMBER_FAIL = 'GET_DATA_BY_SERIAL_NUMBER_FAIL'

// GET_READY_FOR_DISPATCH_REQUEST
export const GET_READY_FOR_DISPATCH_REQUEST = 'GET_READY_FOR_DISPATCH_REQUEST'
export const GET_READY_FOR_DISPATCH_SUCCESS = 'GET_READY_FOR_DISPATCH_SUCCESS'
export const GET_READY_FOR_DISPATCH_FAIL = 'GET_READY_FOR_DISPATCH_FAIL'


// GET_COMPLETE_DISPATCHED_REQUEST
export const GET_COMPLETE_DISPATCHED_REQUEST = 'GET_COMPLETE_DISPATCHED_REQUEST'
export const GET_COMPLETE_DISPATCHED_SUCCESS = 'GET_COMPLETE_DISPATCHED_SUCCESS'
export const GET_COMPLETE_DISPATCHED_FAIL = 'GET_COMPLETE_DISPATCHED_FAIL'


// PUT_PRODUCTION_EDIT_REQUEST
export const PUT_PRODUCTION_EDIT_REQUEST = 'PUT_PRODUCTION_EDIT_REQUEST'
export const PUT_PRODUCTION_EDIT_SUCCESS = 'PUT_PRODUCTION_EDIT_SUCCESS'
export const PUT_PRODUCTION_EDIT_FAIL = 'PUT_PRODUCTION_EDIT_FAIL'



// GET_EDIT_PRODUCTION_DATA_REQUEST
export const GET_EDIT_PRODUCTION_DATA_REQUEST = 'GET_EDIT_PRODUCTION_DATA_REQUEST'
export const GET_EDIT_PRODUCTION_DATA_SUCCESS = 'GET_EDIT_PRODUCTION_DATA_SUCCESS'
export const GET_EDIT_PRODUCTION_DATA_FAIL = 'GET_EDIT_PRODUCTION_DATA_FAIL'



// GET_EDIT_LIST_DATA_PRODUCTION_REQUEST
export const GET_EDIT_LIST_DATA_PRODUCTION_REQUEST = 'GET_EDIT_LIST_DATA_PRODUCTION_REQUEST'
export const GET_EDIT_LIST_DATA_PRODUCTION_SUCCESS = 'GET_EDIT_LIST_DATA_PRODUCTION_SUCCESS'
export const GET_EDIT_LIST_DATA_PRODUCTION_FAIL = 'GET_EDIT_LIST_DATA_PRODUCTION_FAIL'



// RETURN_DISPATCH_DATA_REQUEST
export const RETURN_DISPATCH_DATA_REQUEST = 'RETURN_DISPATCH_DATA_REQUEST'
export const RETURN_DISPATCH_DATA_SUCCESS = 'RETURN_DISPATCH_DATA_SUCCESS'
export const RETURN_DISPATCH_DATA_FAIL = 'RETURN_DISPATCH_DATA_FAIL'




// GET_RETURN_DEVICE_TO_ACCOUNT_REQUEST
export const GET_RETURN_DEVICE_TO_ACCOUNT_REQUEST = 'GET_RETURN_DEVICE_TO_ACCOUNT_REQUEST'
export const GET_RETURN_DEVICE_TO_ACCOUNT_SUCCESS = 'GET_RETURN_DEVICE_TO_ACCOUNT_SUCCESS'
export const GET_RETURN_DEVICE_TO_ACCOUNT_FAIL = 'GET_RETURN_DEVICE_TO_ACCOUNT_FAIL'




// UPDATE_DEVICEID_BY_DISPATCH_REQUEST
export const UPDATE_DEVICEID_BY_DISPATCH_REQUEST = 'UPDATE_DEVICEID_BY_DISPATCH_REQUEST'
export const UPDATE_DEVICEID_BY_DISPATCH_SUCCESS = 'UPDATE_DEVICEID_BY_DISPATCH_SUCCESS'
export const UPDATE_DEVICEID_BY_DISPATCH_FAIL = 'UPDATE_DEVICEID_BY_DISPATCH_FAIL'