// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentDashboard_mainContainer__UWIRp {
    position: relative;
    top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-bottom: 20rem;
}

.PaymentDashboard_dispatchContainer__xGUbG {
    height: 100vh;
    width: 96%;
    border-radius: 25px;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.PaymentDashboard_container__Ohxdt{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    flex-wrap: wrap;
    gap: 2rem;
}
.PaymentDashboard_accountContainer__T25gN{
width: 750px;
height: 21.8rem;
padding: 1rem;
box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/css/PaymentDashboard.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,eAAe;IACf,SAAS;AACb;AACA;AACA,YAAY;AACZ,eAAe;AACf,aAAa;AACb,iDAAiD;AACjD,mBAAmB;AACnB","sourcesContent":[".mainContainer {\n    position: relative;\n    top: 3rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    margin-bottom: 20rem;\n}\n\n.dispatchContainer {\n    height: 100vh;\n    width: 96%;\n    border-radius: 25px;\n    background: rgb(255, 255, 255);\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n}\n.container{\n    display: flex;\n    justify-content: space-between;\n    padding: 1rem;\n    flex-wrap: wrap;\n    gap: 2rem;\n}\n.accountContainer{\nwidth: 750px;\nheight: 21.8rem;\npadding: 1rem;\nbox-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\nborder-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `PaymentDashboard_mainContainer__UWIRp`,
	"dispatchContainer": `PaymentDashboard_dispatchContainer__xGUbG`,
	"container": `PaymentDashboard_container__Ohxdt`,
	"accountContainer": `PaymentDashboard_accountContainer__T25gN`
};
export default ___CSS_LOADER_EXPORT___;
