// store data 
export const STORE_SYSTEM_REQUEST = 'STORE_SYSTEM_REQUEST'
export const STORE_SYSTEM_SUCCESS = 'STORE_SYSTEM_SUCCESS'
export const STORE_SYSTEM_FAIL = 'STORE_SYSTEM_FAIL'

// store data 
export const ALL_HOSPITAL_DATA_REQUEST = 'ALL_HOSPITAL_DATA_REQUEST'
export const ALL_HOSPITAL_DATA_SUCCESS = 'ALL_HOSPITAL_DATA_SUCCESS'
export const ALL_HOSPITAL_DATA_FAIL = 'ALL_HOSPITAL_DATA_FAIL'

// store data 
export const PUT_ALL_STORE_DATA_REQUEST = 'PUT_ALL_STORE_DATA_REQUEST'
export const PUT_ALL_STORE_DATA_SUCCESS = 'PUT_ALL_STORE_DATA_SUCCESS'
export const PUT_ALL_STORE_DATA_FAIL = 'PUT_ALL_STORE_DATA_FAIL'

// GET_NEW_HOSPITAL_DATA_REQUEST
export const GET_NEW_HOSPITAL_DATA_REQUEST = 'GET_NEW_HOSPITAL_DATA_REQUEST'
export const GET_NEW_HOSPITAL_DATA_REQUEST_SUCCESS = 'GET_NEW_HOSPITAL_DATA_REQUEST_SUCCESS'
export const GET_NEW_HOSPITAL_DATA_REQUEST_FAIL = 'GET_NEW_HOSPITAL_DATA_REQUEST_FAIL'

// ALL_FEEEDBACK_DATA_REQUEST_SUCCESS
export const ALL_TICKETS_DATA_BY_EMAIL_REQUEST = 'ALL_TICKETS_DATA_BY_EMAIL_REQUEST'
export const ALL_TICKETS_DATA_BY_EMAIL_REQUEST_SUCCESS = 'ALL_TICKETS_DATA_BY_EMAIL_REQUEST_SUCCESS'
export const ALL_TICKETS_DATA_BY_EMAIL_REQUEST_FAIL = 'ALL_TICKETS_DATA_BY_EMAIL_REQUEST_FAIL'

// FEEDBACK_DATA_REQUEST
export const FEEDBACK_DATA_REQUEST = 'FEEDBACK_DATA_REQUEST'
export const FEEDBACK_DATA_SUCCESS = 'FEEDBACK_DATA_SUCCESS'
export const FEEDBACK_DATA_FAIL = 'FEEDBACK_DATA_FAIL'

// GET_CONCERNED_PERSONDETAILS_BY_NUYMBER_REQUEST
export const GET_CONCERNED_PERSONDETAILS_BY_NUYMBER_REQUEST = 'GET_CONCERNED_PERSONDETAILS_BY_NUYMBER_REQUEST'
export const GET_CONCERNED_PERSONDETAILS_BY_NUYMBER_SUCCESS = 'GET_CONCERNED_PERSONDETAILS_BY_NUYMBER_SUCCESS'
export const GET_CONCERNED_PERSONDETAILS_BY_NUYMBER_FAIL = 'GET_CONCERNED_PERSONDETAILS_BY_NUYMBER_FAIL'

// FEEDBACK_DATA_REQUEST
export const GET_HOSPITAL_REGISTER_DATA_REQUEST = 'GET_HOSPITAL_REGISTER_DATA_REQUEST'
export const GET_HOSPITAL_REGISTER_DATA_SUCCESS = 'GET_HOSPITAL_REGISTER_DATA_SUCCESS'
export const GET_HOSPITAL_REGISTER_DATA_FAIL = 'GET_HOSPITAL_REGISTER_DATA_FAIL'

// GET_HOSPITAL_LIST_BY_PINCODE_REQUEST
export const GET_HOSPITAL_LIST_BY_PINCODE_REQUEST = 'GET_HOSPITAL_LIST_BY_PINCODE_REQUEST'
export const GET_HOSPITAL_LIST_BY_PINCODE_SUCCESS = 'GET_HOSPITAL_LIST_BY_PINCODE_SUCCESS'
export const GET_HOSPITAL_LIST_BY_PINCODE_FAIL = 'GET_HOSPITAL_LIST_BY_PINCODE_FAIL'

// GET_ILLNESS_DATA_REQUEST
export const GET_ILLNESS_DATA_REQUEST = 'GET_ILLNESS_DATA_REQUEST'
export const GET_ILLNESS_DATA_SUCCESS = 'GET_ILLNESS_DATA_SUCCESS'
export const GET_ILLNESS_DATA_FAIL = 'GET_ILLNESS_DATA_FAIL'

// GET_TICKETS_COUNT_REQUEST
export const GET_TICKETS_COUNT_REQUEST = 'GET_TICKETS_COUNT_REQUEST'
export const GET_TICKETS_COUNT_SUCCESS = 'GET_TICKETS_COUNT_SUCCESS'
export const GET_TICKETS_COUNT_FAIL = 'GET_TICKETS_COUNT_FAIL'

// POST_TICKET_SERVICE_DATA_REQUEST
export const POST_TICKET_SERVICE_DATA_REQUEST = 'POST_TICKET_SERVICE_DATA_REQUEST'
export const POST_TICKET_SERVICE_DATA_SUCCESS = 'POST_TICKET_SERVICE_DATA_SUCCESS'
export const POST_TICKET_SERVICE_DATA_FAIL = 'POST_TICKET_SERVICE_DATA_FAIL'


// GET_GRAPH_DATA_COUNT_REQUEST
export const GET_GRAPH_DATA_COUNT_REQUEST = 'GET_GRAPH_DATA_COUNT_REQUEST'
export const GET_GRAPH_DATA_COUNT_SUCCESS = 'GET_GRAPH_DATA_COUNT_SUCCESS'
export const GET_GRAPH_DATA_COUNT_FAIL = 'GET_GRAPH_DATA_COUNT_FAIL'

// POST_SERIALNO_FROM_TICKET_DETAILS_REQUEST
export const POST_SERIALNO_FROM_TICKET_DETAILS_REQUEST = 'POST_SERIALNO_FROM_TICKET_DETAILS_REQUEST'
export const POST_SERIALNO_FROM_TICKET_DETAILS_SUCCESS = 'POST_SERIALNO_FROM_TICKET_DETAILS_SUCCESS'
export const POST_SERIALNO_FROM_TICKET_DETAILS_FAIL = 'POST_SERIALNO_FROM_TICKET_DETAILS_FAIL'