
export const DEVICE_FAIL = "DEVICE_FAIL"
export const DEVICE_REQUEST = "DEVICE_REQUEST"
export const DEVICE_SUCCESS = "DEVICE_SUCCESS"

// GET MODEL CODE
export const GET_MODEL_CODE_REQUEST = 'GET_MODEL_CODE_REQUEST'
export const GET_MODEL_CODE_SUCCESS = 'GET_MODEL_CODE_SUCCESS'
export const GET_MODEL_CODE_FAIL = 'GET_MODEL_CODE_FAIL'

export const REGISTER_NEW_DEVICE_REQUEST = "REGISTER_NEW_DEVICE_REQUEST "
export const REGISTER_NEW_DEVICE_FAIL = "REGISTER_NEW_DEVICE_FAIL"
export const REGISTER_NEW_DEVICE_SUCCESS = "REGISTER_NEW_DEVICE_SUCCESS"

// About Section
export const GET_ABOUT_SECTION_BY_ID_REQUEST = 'GET_ABOUT_SECTION_BY_ID_REQUEST'
export const GET_ABOUT_SECTION_BY_ID_SUCCESS = 'GET_ABOUT_SECTION_BY_ID_SUCCESS'
export const GET_ABOUT_SECTION_BY_ID_FAIL = 'GET_ABOUT_SECTION_BY_ID_FAIL'

//Device Events
export const GET_DEVICE_EVENTS_BY_ID_REQUEST = 'GET_DEVICE_EVENTS_BY_ID_REQUEST'
export const GET_DEVICE_EVENTS_BY_ID_SUCCESS = 'GET_DEVICE_EVENTS_BY_ID_SUCCESS'
export const GET_DEVICE_EVENTS_BY_ID_FAIL = 'GET_DEVICE_EVENTS_BY_ID_FAIL'

//Device Alerts
export const GET_DEVICE_ALARMS_BY_ID_REQUEST = 'GET_DEVICE_ALARMS_BY_ID_REQUEST'
export const GET_DEVICE_ALARMS_BY_ID_SUCCESS = 'GET_DEVICE_ALARMS_BY_ID_SUCCESS'
export const GET_DEVICE_ALARMS_BY_ID_FAIL = 'GET_DEVICE_ALARMS_BY_ID_FAIL'

//Device Trends
export const GET_DEVICE_TRENDS_BY_ID_REQUEST = 'GET_DEVICE_TRENDS_BY_ID_REQUEST'
export const GET_DEVICE_TRENDS_BY_ID_SUCCESS = 'GET_DEVICE_TRENDS_BY_ID_SUCCESS'
export const GET_DEVICE_TRENDS_BY_ID_FAIL = 'GET_DEVICE_TRENDS_BY_ID_FAIL'

//Device CALIBRATION
export const GET_DEVICE_CALIBRATION_BY_ID_REQUEST = 'GET_DEVICE_CALIBRATION_BY_ID_REQUEST'
export const GET_DEVICE_CALIBRATION_BY_ID_SUCCESS = 'GET_DEVICE_CALIBRATION_BY_ID_SUCCESS'
export const GET_DEVICE_CALIBRATION_BY_ID_FAIL = 'GET_DEVICE_CALIBRATION_BY_ID_FAIL'

//Device Logs
export const GET_DEVICE_LOGS_BY_ID_REQUEST = 'GET_DEVICE_LOGS_BY_ID_REQUEST'
export const GET_DEVICE_LOGS_BY_ID_SUCCESS = 'GET_DEVICE_LOGS_BY_ID_SUCCESS'
export const GET_DEVICE_LOGS_BY_ID_FAIL = 'GET_DEVICE_LOGS_BY_ID_FAIL'

export const GET_REGISTERED_DEVICE_DETAILS_REQUEST = 'GET_REGISTERED_DEVICE_DETAILS_REQUEST'
export const GET_REGISTERED_DEVICE_DETAILS_SUCCESS = 'GET_REGISTERED_DEVICE_DETAILS_SUCCESS'
export const GET_REGISTERED_DEVICE_DETAILS_FAIL = 'GET_REGISTERED_DEVICE_DETAILS_FAIL'

export const UPDATE_DEVICE_DETAILS_BY_ID_REQUEST = 'UPDATE_DEVICE_DETAILS_BY_ID_REQUEST'
export const UPDATE_DEVICE_DETAILS_BY_ID_SUCCESS = 'UPDATE_DEVICE_DETAILS_BY_ID_SUCCESS'
export const UPDATE_DEVICE_DETAILS_BY_ID_FAIL = 'UPDATE_DEVICE_DETAILS_BY_ID_FAIL'

export const GET_ALL_LOG_BY_CODE_FAIL = 'GET_ALL_LOG_BY_CODE_FAIL'
export const GET_ALL_LOG_BY_CODE_REQUEST = 'GET_ALL_LOG_BY_CODE_REQUEST'
export const GET_ALL_LOG_BY_CODE_SUCCESS = 'GET_ALL_LOG_BY_CODE_SUCCESS'

export const GET_LOG_MSG_OCCURENCE_REQUEST = 'GET_LOG_MSG_OCCURENCE_REQUEST'
export const GET_LOG_MSG_OCCURENCE_SUCCESS = 'GET_LOG_MSG_OCCURENCE_SUCCESS'
export const GET_LOG_MSG_OCCURENCE_FAIL = 'GET_LOG_MSG_OCCURENCE_FAIL'

// GET CRASH DATA
export const GET_DEVICE_CRASH_ANALYTICS_DATA_REQUEST = 'GET_DEVICE_CRASH_ANALYTICS_DATA_REQUEST'
export const GET_DEVICE_CRASH_ANALYTICS_DATA_SUCCESS = 'GET_DEVICE_CRASH_ANALYTICS_DATA_SUCCESS'
export const GET_DEVICE_CRASH_ANALYTICS_DATA_FAIL = 'GET_DEVICE_CRASH_ANALYTICS_DATA_FAIL'
// GET COUNT MSG OCCURRENCE
export const GET_DEVICE_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST = 'GET_DEVICE_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_REQUEST'
export const GET_DEVICE_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_SUCCESS = 'GET_DEVICE_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_SUCCESS'
export const GET_DEVICE_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_FAIL = 'GET_DEVICE_LOG_MSG_OCCURRENCE_COUNT_WRT_DATE_FAIL'

// GET COUNT MSG OCCURRENCE
export const GET_SERVICE_RECORDS_DETAILS = 'GET_SERVICE_RECORDS_DETAILS'
export const GET_SERVICE_RECORDS_DETAILS_SUCCESS = 'GET_SERVICE_RECORDS_DETAILS_SUCCESS'
export const GET_SERVICE_RECORDS_DETAILS_FAIL = 'GET_SERVICE_RECORDS_DETAILS_FAIL'

// GET Single DeviceId 
export const GET_SINGLE_DEVICEID_REQUEST = 'GET_SINGLE_DEVICEID_REQUEST'
export const GET_SINGLE_DEVICEID_SUCCESS = 'GET_SINGLE_DEVICEID_SUCCESS'
export const GET_SINGLE_DEVICEID_FAIL = 'GET_SINGLE_DEVICEID_FAIL'

// GET Single DeviceId By UserId
export const GET_SINGLE_DEVICEIDBY_USERID_REQUEST = 'GET_SINGLE_DEVICEIDBY_USERID_REQUEST'
export const GET_SINGLE_DEVICEIDBY_USERID_SUCCESS = 'GET_SINGLE_DEVICEIDBY_USERID_SUCCESS'
export const GET_SINGLE_DEVICEIDBY_USERID_FAIL = 'GET_SINGLE_DEVICEIDBY_USERID_FAIL'

// GET Single UPLOAD FILE BY ID
export const GET_SINGLE_UPLOAD_FILE_REQUEST = 'GET_SINGLE_UPLOAD_FILE_REQUEST'
export const GET_SINGLE_UPLOAD_FILE_SUCCESS = 'GET_SINGLE_UPLOAD_FILE_SUCCESS'
export const GET_SINGLE_UPLOAD_FILE_FAIL = 'GET_SINGLE_UPLOAD_FILE_FAIL'

// GET_SERIAL_NO_BY_DEVICE_ID_REQUEST
export const GET_SERIAL_NO_BY_DEVICE_ID_REQUEST = 'GET_SERIAL_NO_BY_DEVICE_ID_REQUEST'
export const GET_SERIAL_NO_BY_DEVICE_ID_REQUEST_SUCCESS = 'GET_SERIAL_NO_BY_DEVICE_ID_REQUEST_SUCCESS'
export const GET_SERIAL_NO_BY_DEVICE_ID_REQUEST_FAIL = 'GET_SERIAL_NO_BY_DEVICE_ID_REQUEST_FAIL'


// GET_PATIENT_DETAILS_REQUEST
export const GET_PATIENT_DETAILS_REQUEST = 'GET_PATIENT_DETAILS_REQUEST'
export const GET_PATIENT_DETAILS_SUCCESS = 'GET_PATIENT_DETAILS_SUCCESS'
export const GET_PATIENT_DETAILS_FAIL = 'GET_PATIENT_DETAILS_FAIL'

// GET_PATIENT_DETAILS_BY_UHID_REQUEST
export const GET_PATIENT_DETAILS_BY_UHID_REQUEST = 'GET_PATIENT_DETAILS_BY_UHID_REQUEST'
export const GET_PATIENT_DETAILS_BY_UHID_SUCCESS = 'GET_PATIENT_DETAILS_BY_UHID_SUCCESS'
export const GET_PATIENT_DETAILS_BY_UHID_FAIL = 'GET_PATIENT_DETAILS_BY_UHID_FAIL'

// GET_PATIENT_DETAILS_BY_UHID_REQUEST
export const PUT_PATIENT_DATA_REQUEST = 'PUT_PATIENT_DATA_REQUEST'
export const PUT_PATIENT_DATA_SUCCESS = 'PUT_PATIENT_DATA_SUCCESS'
export const PUT_PATIENT_DATA_FAIL = 'PUT_PATIENT_DATA_FAIL'

// ADD_PATIENT_DIAGNOSE_REQUEST
export const ADD_PATIENT_DIAGNOSE_REQUEST = 'ADD_PATIENT_DIAGNOSE_REQUEST'
export const ADD_PATIENT_DIAGNOSE_SUCCESS = 'ADD_PATIENT_DIAGNOSE_SUCCESS'
export const ADD_PATIENT_DIAGNOSE_FAIL = 'ADD_PATIENT_DIAGNOSE_FAIL'

// GET_PATIENT_DIAGNOSE_REQUEST
export const GET_PATIENT_DIAGNOSE_REQUEST = 'GET_PATIENT_DIAGNOSE_REQUEST'
export const GET_PATIENT_DIAGNOSE_SUCCESS = 'GET_PATIENT_DIAGNOSE_SUCCESS'
export const GET_PATIENT_DIAGNOSE_FAIL = 'GET_PATIENT_DIAGNOSE_FAIL'

// GET_UHIDS_LIST_REQUEST
export const GET_UHIDS_LIST_REQUEST = 'GET_UHIDS_LIST_REQUEST'
export const GET_UHIDS_LIST_SUCCESS = 'GET_UHIDS_LIST_SUCCESS'
export const GET_UHIDS_LIST_FAIL = 'GET_UHIDS_LIST_FAIL'

// GET_UHIDS_LIST_REQUEST
export const GET_REQUEST_USER_DATA_REQUEST = 'GET_REQUEST_USER_DATA_REQUEST'
export const GET_REQUEST_USER_DATA_SUCCESS = 'GET_REQUEST_USER_DATA_SUCCESS'
export const GET_REQUEST_USER_DATA_FAIL = 'GET_REQUEST_USER_DATA_FAIL'

// GET_UHIDS_LIST_REQUEST
export const GET_USER_DEVICE_REQUEST = 'GET_USER_DEVICE_REQUEST'
export const GET_USER_DEVICE_SUCCESS = 'GET_USER_DEVICE_SUCCESS'
export const GET_USER_DEVICE_FAIL = 'GET_USER_DEVICE_FAIL'

// GET_SINGLE_PRODUCTION_DATA_REQUEST
export const GET_SINGLE_PRODUCTION_DATA_REQUEST = 'GET_SINGLE_PRODUCTION_DATA_REQUEST'
export const GET_SINGLE_PRODUCTION_DATA_SUCCESS = 'GET_SINGLE_PRODUCTION_DATA_SUCCESS'
export const GET_SINGLE_PRODUCTION_DATA_FAIL = 'GET_SINGLE_PRODUCTION_DATA_FAIL'

// POST_EDIT_ACCOUNT_DETAILS_REQUEST
export const POST_EDIT_ACCOUNT_DETAILS_REQUEST = 'POST_EDIT_ACCOUNT_DETAILS_REQUEST'
export const POST_EDIT_ACCOUNT_DETAILS_SUCCESS = 'POST_EDIT_ACCOUNT_DETAILS_SUCCESS'
export const POST_EDIT_ACCOUNT_DETAILS_FAIL = 'POST_EDIT_ACCOUNT_DETAILS_FAIL'

// NEW_DEVICES_REQUEST
export const NEW_DEVICES_REQUEST = 'NEW_DEVICES_REQUEST'
export const NEW_DEVICES_SUCCESS = 'NEW_DEVICES_SUCCESS'
export const NEW_DEVICES_FAIL = 'NEW_DEVICES_FAIL'

// GET_NEW_DEVICES_EVENTS_BY_ID_REQUEST
export const GET_NEW_DEVICES_EVENTS_BY_ID_REQUEST = 'GET_NEW_DEVICES_EVENTS_BY_ID_REQUEST'
export const GET_NEW_DEVICES_EVENTS_BY_ID_SUCCESS = 'GET_NEW_DEVICES_EVENTS_BY_ID_SUCCESS'
export const GET_NEW_DEVICES_EVENTS_BY_ID_FAIL = 'GET_NEW_DEVICES_EVENTS_BY_ID_FAIL'

// GET_NEW_DEVICES_EVENTS_BY_ID_REQUEST
export const GET_NEW_DEVICES_ALARMS_BY_ID_REQUEST = 'GET_NEW_DEVICES_ALARMS_BY_ID_REQUEST'
export const GET_NEW_DEVICES_ALARMS_BY_ID_SUCCESS = 'GET_NEW_DEVICES_ALARMS_BY_ID_SUCCESS'
export const GET_NEW_DEVICES_ALARMS_BY_ID_FAIL = 'GET_NEW_DEVICES_ALARMS_BY_ID_FAIL'

// GET_NEW_DEVICES_LOGS_BY_ID_REQUEST
export const GET_NEW_DEVICES_LOGS_BY_ID_REQUEST = 'GET_NEW_DEVICES_LOGS_BY_ID_REQUEST'
export const GET_NEW_DEVICES_LOGS_BY_ID_SUCCESS = 'GET_NEW_DEVICES_LOGS_BY_ID_SUCCESS'
export const GET_NEW_DEVICES_LOGS_BY_ID_FAIL = 'GET_NEW_DEVICES_LOGS_BY_ID_FAIL'

// GET_NEW_DEVICES_OF_TRENDS_BY_ID_REQUEST
export const GET_NEW_DEVICES_OF_TRENDS_BY_ID_REQUEST = 'GET_NEW_DEVICES_OF_TRENDS_BY_ID_REQUEST'
export const GET_NEW_DEVICES_OF_TRENDS_BY_ID_SUCCESS = 'GET_NEW_DEVICES_OF_TRENDS_BY_ID_SUCCESS'
export const GET_NEW_DEVICES_OF_TRENDS_BY_ID_FAIL = 'GET_NEW_DEVICES_OF_TRENDS_BY_ID_FAIL'

// GET_PATIENT_DETAILS_OF_NEW_DEVICES_BY_UHID_REQUEST
export const GET_PATIENT_DETAILS_OF_NEW_DEVICES_BY_UHID_REQUEST = 'GET_PATIENT_DETAILS_OF_NEW_DEVICES_BY_UHID_REQUEST'
export const GET_PATIENT_DETAILS_OF_NEW_DEVICES_BY_UHID_SUCCESS = 'GET_PATIENT_DETAILS_OF_NEW_DEVICES_BY_UHID_SUCCESS'
export const GET_PATIENT_DETAILS_OF_NEW_DEVICES_BY_UHID_FAIL = 'GET_PATIENT_DETAILS_OF_NEW_DEVICES_BY_UHID_FAIL'


// GET_PATIENT_DISCHARGE_REQUEST
export const GET_PATIENT_DISCHARGE_REQUEST = 'GET_PATIENT_DISCHARGE_REQUEST'
export const GET_PATIENT_DISCHARGE_SUCCESS = 'GET_PATIENT_DISCHARGE_SUCCESS'
export const GET_PATIENT_DISCHARGE_FAIL = 'GET_PATIENT_DISCHARGE_FAIL'

// POST_DEVICE_ID_FROM_LOCK_REQUEST
export const POST_DEVICE_ID_FROM_LOCK_REQUEST = 'POST_DEVICE_ID_FROM_LOCK_REQUEST'
export const POST_DEVICE_ID_FROM_LOCK_SUCCESS = 'POST_DEVICE_ID_FROM_LOCK_SUCCESS'
export const POST_DEVICE_ID_FROM_LOCK_FAIL = 'POST_DEVICE_ID_FROM_LOCK_FAIL'

// GET_ALL_USER_COUNT_DATA_REQUEST
export const GET_ALL_USER_COUNT_DATA_REQUEST = 'GET_ALL_USER_COUNT_DATA_REQUEST'
export const GET_ALL_USER_COUNT_DATA_SUCCESS = 'GET_ALL_USER_COUNT_DATA_SUCCESS'
export const GET_ALL_USER_COUNT_DATA_FAIL = 'GET_ALL_USER_COUNT_DATA_FAIL'

// GET_INDIAN_MEDIDINE_ACTION
export const GET_INDIAN_MEDIDINE_ACTION = 'GET_INDIAN_MEDIDINE_ACTION'
export const GET_INDIAN_MEDIDINE_SUCCESS = 'GET_INDIAN_MEDIDINE_SUCCESS'
export const GET_INDIAN_MEDIDINE_FAIL = 'GET_INDIAN_MEDIDINE_FAIL'

// PUT_ADD_ILLNESS_MEDICINE_REQUEST
export const PUT_ADD_ILLNESS_MEDICINE_REQUEST = 'PUT_ADD_ILLNESS_MEDICINE_REQUEST'
export const PUT_ADD_ILLNESS_MEDICINE_SUCCESS = 'PUT_ADD_ILLNESS_MEDICINE_SUCCESS'
export const PUT_ADD_ILLNESS_MEDICINE_FAIL = 'PUT_ADD_ILLNESS_MEDICINE_FAIL'

// PUT_MEDICINE_AND_ILLNESS_REQUEST
export const PUT_MEDICINE_AND_ILLNESS_REQUEST = 'PUT_MEDICINE_AND_ILLNESS_REQUEST'
export const PUT_MEDICINE_AND_ILLNESS_SUCCESS = 'PUT_MEDICINE_AND_ILLNESS_SUCCESS'
export const PUT_MEDICINE_AND_ILLNESS_FAIL = 'PUT_MEDICINE_AND_ILLNESS_FAIL'

// PUT_MEDICINE_AND_ILLNESS_REQUEST
export const PUT_END_DATE_REQUEST = 'PUT_END_DATE_REQUEST'
export const PUT_END_DATE_SUCCESS = 'PUT_END_DATE_SUCCESS'
export const PUT_END_DATE_FAIL = 'PUT_END_DATE_FAIL'

// GET_GRAPH_OF_TREND_MEDICINE_REQUEST
export const GET_GRAPH_OF_TREND_MEDICINE_REQUEST = 'GET_GRAPH_OF_TREND_MEDICINE_REQUEST'
export const GET_GRAPH_OF_TREND_MEDICINE_SUCCESS = 'GET_GRAPH_OF_TREND_MEDICINE_SUCCESS'
export const GET_GRAPH_OF_TREND_MEDICINE_FAIL = 'GET_GRAPH_OF_TREND_MEDICINE_FAIL'

// GET_DEVELOPER_OPTIONS_DATA_REQUEST
export const GET_DEVELOPER_OPTIONS_DATA_REQUEST = 'GET_DEVELOPER_OPTIONS_DATA_REQUEST'
export const GET_DEVELOPER_OPTIONS_DATA_SUCCESS = 'GET_DEVELOPER_OPTIONS_DATA_SUCCESS'
export const GET_DEVELOPER_OPTIONS_DATA_FAIL = 'GET_DEVELOPER_OPTIONS_DATA_FAIL'

// POST_TRACK_HISTORY_DATA_REQUEST
export const POST_TRACK_HISTORY_DATA_REQUEST = 'POST_TRACK_HISTORY_DATA_REQUEST'
export const POST_TRACK_HISTORY_DATA_SUCCESS = 'POST_TRACK_HISTORY_DATA_SUCCESS'
export const POST_TRACK_HISTORY_DATA_FAIL = 'POST_TRACK_HISTORY_DATA_FAIL'

// ALL_DEVICE_DATA_AGVA_MINI_REQUEST
export const ALL_DEVICE_DATA_AGVA_MINI_REQUEST = 'ALL_DEVICE_DATA_AGVA_MINI_REQUEST'
export const ALL_DEVICE_DATA_AGVA_MINI_SUCCESS = 'ALL_DEVICE_DATA_AGVA_MINI_SUCCESS'
export const ALL_DEVICE_DATA_AGVA_MINI_FAIL = 'ALL_DEVICE_DATA_AGVA_MINI_FAIL'

// GET_SINGLE_DATA_AGVA_MINI_REQUEST
export const GET_SINGLE_DATA_AGVA_MINI_REQUEST = 'GET_SINGLE_DATA_AGVA_MINI_REQUEST'
export const GET_SINGLE_DATA_AGVA_MINI_SUCCESS = 'GET_SINGLE_DATA_AGVA_MINI_SUCCESS'
export const GET_SINGLE_DATA_AGVA_MINI_FAIL = 'GET_SINGLE_DATA_AGVA_MINI_FAIL'

// PUT_DEMO_END_DATE_REQUEST
export const PUT_DEMO_END_DATE_REQUEST = 'PUT_DEMO_END_DATE_REQUEST'
export const PUT_DEMO_END_DATE_SUCCESS = 'PUT_DEMO_END_DATE_SUCCESS'
export const PUT_DEMO_END_DATE_FAIL = 'PUT_DEMO_END_DATE_FAIL'